import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getPools = params => axios.get(`${getAccountId()}/number-pools`, { params })
const getAllPools = () => axios.get(`${getAccountId()}/number-pools-all`)
const getJSCode = params => axios.get(`${getAccountId()}/number-pools-action/get-js`, { params })
const getDetailPool = poolId => axios.get(`${getAccountId()}/number-pools/${poolId}`)
const createPool = formData => axios.post(`${getAccountId()}/number-pools`, formData)
const updatePool = ({ poolId, formData }) => axios.patch(`${getAccountId()}/number-pools/${poolId}`, formData)
const attachNumbersToPool = formData => axios.post(`${getAccountId()}/number-pools/attach`, formData)
const detachNumbersFromPool = formData => axios.post(`${getAccountId()}/number-pools/dettach`, formData)
const attachPoolToNumber = formData => axios.post(`${getAccountId()}/number-pools-action/attach-to-number`, formData)
const autoBuyNumbersForPool = poolId => axios.patch(`${getAccountId()}/number-pools/${poolId}/numbers`)
const detachPoolFromNumber = params => axios.delete(`${getAccountId()}/number-pools-action/attach-to-number`, { params })
const deletePool = poolId => axios.delete(`${getAccountId()}/number-pools/${poolId}`)
const detachAllNumbersFromPool = ({ poolId, params }) => axios.delete(`${getAccountId()}/number-pools/${poolId}/numbers`, { params })

export {
  getPools, getAllPools, getJSCode, getDetailPool, createPool, updatePool, attachNumbersToPool, detachNumbersFromPool, attachPoolToNumber, autoBuyNumbersForPool, detachPoolFromNumber, deletePool, detachAllNumbersFromPool
}