import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getNumbers = params => axios.get(`${getAccountId()}/numbers/paginated`, { params })
const getAllNumbers = () => axios.get(`${getAccountId()}/numbers/all`)
const getNumbersFees = params => axios.get(`${getAccountId()}/numbers/fees`, { params })
const getSearchNumbers = params => axios.get(`${getAccountId()}/numbers/search`, { params })
const buyNumber = formData => axios.post(`${getAccountId()}/numbers`, formData)
const updateNumber = ({ numberId, formData }) => axios.patch(`${getAccountId()}/numbers/${numberId}`, formData)
const deleteNumber = numberId => axios.delete(`${getAccountId()}/numbers/${numberId}`)

const getNumbersForVendor = params => axios.get(`${getAccountId()}/vendor/numbers/paginated`, { params })
const getSearchNumbersForVendor = params => axios.get(`${getAccountId()}/vendor/numbers/search`, { params })
const buyNumberForVendor = formData => axios.post(`${getAccountId()}/vendor/numbers`, formData)
const updateNumberForVendor = ({ numberId, formData }) => axios.patch(`${getAccountId()}/vendor/numbers/${numberId}`, formData)
const deleteNumberForVendor = numberId => axios.delete(`${getAccountId()}/vendor/numbers/${numberId}`)

export {
  getNumbers, getAllNumbers, getNumbersFees, getSearchNumbers, buyNumber, updateNumber, deleteNumber, getNumbersForVendor, getSearchNumbersForVendor, buyNumberForVendor, updateNumberForVendor, deleteNumberForVendor
}